import React, { useEffect, useRef, useState } from "react";
import Container from "../../../components/chat-channel/Container";
import { Link } from "gatsby";
import { useMedia } from "use-media";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import {
  AdHomePage_November,
  ISOSecurity_November,
} from "../../conversational-ai-platform";
import {
  Header,
  Footer,
  TopHeadSecPaidLanding,
  TabsSection,
  ChatbotsCategories,
  ClientTestimonials,
  BookDemoCalendly,
  demoData,
  tabContent,
  heroImage,
  chatbotApps,
  handleClickBookDemo,
  ClientTestimonialsCalendly,
} from "./itsm-chatbot";
import { AppComparison } from "./moveworks-alternative";

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "WebSite",
  name: "Workativ",
  url: "https://workativ.com",
  description:
    " Workativ Assistant is easy to use 'AI + Automation' SaaS product for companies to build an intelligent Virtual Assistant/Chatbot with pre-built automations, to provide employee support, reduce Help Desk costs, and increase productivity. No coding required! ",
  sameAs: [
    "https://www.youtube.com/channel/UCDNRPFuv8tI7XDm117XV9eg",
    "https://twitter.com/workativ",
    "https://www.facebook.com/workativ",
  ],
  sourceOrganization: {
    "@type": "Organization",
    name: "Workativ Software Solutions",
    description:
      " Workativ offers an intelligent AI + Automation platform to build and deploy purpose-built Chatbot for workplace support. ",
    url: "https://www.workativ.com",
    logo: "https://workativ.com/workativ-logo.196a2d6c.png",
    founder: {
      "@type": "Person",
      name: "Imran Quraishy",
    },
    sameAs: ["https://www.linkedin.com/company/workativ-com/"],
  },
};
export default function HomePage() {
  return (
    <>
      <TitleAndMetaTags
        title="Workativ Assistant: Your Aisera Alternative"
        description="Explore Workativ Assistant, the Aisera alternative that streamlines employee support with advanced automation."
        keywords={[
          "IT Helpdesk Chatbot",
          "HR Chatbot",
          "Service Desk Chatbot",
          "Aisera Alternative",
        ]}
        homepagrUrl
        schema={ldSchema}
      />
      <div className="">
        <Container>
          <Header />
          <TopHeadSecPaidLanding
            heading={
              "Automate your Employee Support with Gen AI Chatbot Automation"
            }
            buttonText={"Book a demo"}
            heroImage={heroImage}
            onButtonClick={handleClickBookDemo}
            textColorBlue={true}
          />
          <TabsSection
            tabContent={tabContent}
            onButtonClick={handleClickBookDemo}
          />
          <ChatbotsCategories
            heading={"Integrate chatbot with app workflows in seconds."}
            description={
              "Pick from over 1000+ pre-built automations on popular apps, and automate your employee support."
            }
            appsImage={chatbotApps}
          />
          <AdHomePage_November />
          <AppComparison
            backgroundColorOne={"#D3F1FF"}
            backgroundColorTwo={"#CCFFEC"}
            header={"See how Workativ stacks up against the competition"}
            subHeader={
              "Enterprise Support Automation Category | Gen AI Chatbot Platform"
            }
            tableData={dataForTable}
          />
          <ClientTestimonials withoutButton={true} onButtonClick={handleClickBookDemo} />

          <BookDemoCalendly
            heading={
              "See Workativ’s 4-in-1 Gen AI support automation platform in action"
            }
            subHeading={"BOOK A 30-MINUTE DEMO"}
          />
          <ISOSecurity_November noLink={true} />
          <Footer />
        </Container>
      </div>
    </>
  );
}

const dataForTable = [
  {
    heading: "",
    contents: [
      "Ease of Admin",
      "Ease of Customizations",
      "App Workflow Automation",
      "Knowledge AI",
      "Multi-channel Live Chat Inbox",
    ],
  },
  {
    heading: "AISERA",
    contents: [
      "Vendor dependency",
      "Vendor dependency",
      "via custom API connectors",
      "Enterprise AI Search",
      "Not available",
    ],
  },
  {
    heading: "",
    contents: ["", "", "", "", ""],
  },
  {
    heading: "Workativ",
    contents: [
      "Excellent",
      "Full flexibility via no-code platform",
      "via no-code app workflow <br/> automation builder",
      "Knowledge AI GPT",
      "AI-powered Live Chat Inbox",
    ],
  },
];
